.add-plan {
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    transition: 0.4s;
    height: 60px;
    padding: 0 10px;
    border-radius: 25px;
    cursor: pointer;
    border: 1px solid transparent;
    position: relative;
    box-shadow: "0px 4px 20px rgba(0, 0, 0, 0.2)";
    background: rgb(230, 230, 230);
    margin-bottom: 2rem;
}

.add-plan:hover {
    width: 200px;
    transition: 0.4s;
    border-radius: 25px;
    background-color: "rgba(0, 0, 0, 0.05)";
}

.header {
    display: none;
    white-space: nowrap;
    padding-left: 15px;
    position: absolute;
    left: 40px;
    top: 50%;
    transform: translateY(-50%);
    font-size: large;
    font-weight: bold;
}

.add-plan:hover .header {
    display: block;
}