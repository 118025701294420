@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Hebrew:wght@400;600;700&display=swap');




.highlighted-word {
    background-color: rgb(233, 242, 255);
    padding: 2px 6px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    font-weight: bold;
    display: inline-block;
    margin: 0 2px;
}