@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Hebrew:wght@400;600;700&display=swap');

.swal-overlay {
  z-index: 2000;
}

@font-face {
  font-family: 'ExpoArabic';
  src: url('../public/assets/fonts/ExpoArabic.ttf') format('truetype');
}

.strikethrough {
  position: relative;
  display: inline-block;
}

.strikethrough::after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  border-top: 3px solid red;
  transform: translateY(-50%) rotate(-20deg);
}
